@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --square-size: 16vh;
  }

  @media (min-width: 900px) {
    :root {
      --square-size: 18vh;
    }
  }
  
  @media (min-width: 1024px) {
    :root {
      --square-size: 20vh;
    }
  }

  .font-outline {
    -webkit-text-stroke: 1px white;
  }
  .font-outline-dark {
    -webkit-text-stroke: 1px rgb(55, 121, 252);
  }
  
  .pattern-bg, .pattern-bg-dark, .pattern-bg-title {
    background-size: 6px 6px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 6px);
    align-content: center;
  }
  
  .pattern-bg {
    background-image: radial-gradient(circle, rgba(255, 255, 255, 0.1) 3px, transparent 3px);
  }
  
  .pattern-bg-dark {
    background-image: radial-gradient(circle, rgba(8, 32, 80, 0.2) 3px, transparent 3px);
  }
}

body {
  max-width: 100vw;
  height: 100vh;
  margin: auto;
  color: white;
}

h1, h2, h3 {
  font-weight: bold;
  text-align: center;
  font-weight: normal;
  padding-bottom: 30px;
}

h1 {
  font-size: xx-large;
}

h2 {
  font-size: larger;
}

h3 {
  font-size: large;
}

.row-0 {
  grid-row-start: 1;
  grid-column-start: 1;
}

.row-1 {
  grid-row-start: 1;
  grid-column-start: 3;
}

.row-2 {
  grid-row-start: 2;
  grid-column-start: 1;
}

.row-3 {
  grid-row-start: 2;
  grid-column-start: 3;
}

.shining-box {
  background: 
    linear-gradient(90deg,#0000 30%,rgba(255,255,255,0.5) 50%,#0000 70%)
    rgba(255, 255, 255, 0.5);
}

.shining-animation {
  background-size:300% 100%;
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    background-position: right;    
  }
}

/* ===== Scrollbar ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgba(37, 100, 235, 0.5) rgba(255, 255, 255, 0.3);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(37, 100, 235, 0.5);
}